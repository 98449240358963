import React , { useEffect } from "react";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import Testimonail from "../components/testimonial";

//AOS
import AOS from "aos";
import "aos/dist/aos.css";

export default function VirutalMirror() {

  useEffect(() => {

    AOS.init({
      
    });
    
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          OIC POS for essential for the success of optical businesses
        </title>
        <meta httpEquiv="content-language" content="en-IN" />
        <meta
          name="title"
          content="OIC POS for essential for the success of optical businesses"
        />
        <meta
          name="description"
          content="OIC's fully integrated retail POS is the technology for the future. Point of sale (POS) systems help optical stores streamline processes & drive revenue growth."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.oicapps.com/" />
        <meta
          property="og:description"
          content="OIC's fully integrated retail POS is the technology for the future. Point of sale (POS) systems help optical stores streamline processes & drive revenue growth."
        />
      </Helmet>
      <Layout>
        <div
          className="bannerImage"
          style={{
            backgroundImage: `url("https://oicweb-media.s3.ap-south-1.amazonaws.com/pos-banner.jpg")`,
          }}
        >
          <img src="" alt="OIC POS Technology" className="altImage" />
          <div className="container">
            <div className="row">
              <div className="col-md-6 bannerTextPadder">
                <h1>
                  <span>POS</span> FOR <span>UNIFYING IN-STORE</span> AND{" "}
                  <span>ONLINE SALES.</span>
                </h1>
                <a href="https://crm.oicapps.com/" target="_blank" className="buttonStyleWhite hvr-forward">
                  Start Free Demo
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="padding60" style={{ background: "#ECEDEF" }}>
          <div className="container" data-aos="fade-left" data-aos-duration="400">
            <div className="row aligner">
              <div className="col-md-2"></div>
              <div className="col-md-8 text-center">
                <h2>
                  <span className="realistic-marker-highlight">POS</span>
                </h2>

                <p style={{ textAlign: "center", lineHeight: "2" }}>
                  POS empowers retailers with a user-friendly interface that
                  aims to deliver seamless retail experiences. To keep up with
                  the growing trends and changing customer needs, retail point
                  of sale solutions now enable retailers to perform their daily
                  business with increased efficiency. Quick and easy approach to
                  billing.
                </p>
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>
        </div>

        <div className="padding60">
          <div className="container" data-aos="fade-right" data-aos-duration="400">
            <div className="row">
              <div className="col-md-12 text-center">
                <h2>
                  <span className="realistic-marker-highlight">Features</span>
                </h2>
              </div>
              <div className="container IconBoxText">
                <div className="row featureglass">
                  <div className="featureglassesP"><img src={require("../img/hp/Features_Glass.png")} /></div>
                  <div className="col-md-6 text-center">
                    <div className="transitionRingsL" />
                    <div className="transitionRingsModifiedL" />
                    <div className="featureMarginCorrection IconBox blinkTransition">
                      <img className="transitionPosImages"></img>
                      <p className="transitionPosText">
                        {/* <strong>Ease Billing</strong> */}
                      </p>
                    </div>
                    <br></br>
                    <p className="featureIconDesc featureMarginTextCorrection">
                      POS system empowers minimizing long wait times during peak
                      business hours for billing. OIC POS has the record of
                      customer details for the retailer in secured data.
                    </p>
                  </div>
                  <div className="col-md-6 text-center">
                    <div className="transitionRingsR" />
                    <div className="transitionRingsModifiedR" />
                    <div className="IconBox blinkTransition">
                      <img className="transitionPosImages transitionDirection"></img>
                      <p className="transitionPosText transitionDirection">
                        {/* <strong>Simplify Your Day, Every Day</strong> */}
                      </p>
                    </div>
                    <br></br>
                    <p className="featureIconDesc">
                      Breathe a sigh of relief. Manual managing of those pesky
                      day-to-day tasks, like inventory management, vendor
                      management, customer flow-up reports, etc..
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
        <div className="padding60 blueBackground">
          <section className="container-fluid " data-aos="fade-left" data-aos-duration="400">
            <div className="row aligner">
              <div className="col-md-2"></div>
              <div className="col-md-4">
                <h2 className="realistic-marker-highlight">Customer Data</h2>
                <p>
                  Update any new customer information. Retailers' data is
                  protected. OIC protects the privacy of its customers'
                  information. Quickly obtain exciting customer information,
                  including their name.
                </p>
                <a href="#" className="buttonStyle hvr-forward">
                  Try Now
                </a>
                <br></br> <br></br>
                <br></br> <br></br>
              </div>
              <div className="col-md-6 noPadding">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/pos-01.jpg"
                  className="img-responsive" alt="Customer data showing on the tab"
                ></img>
              </div>
            </div>
          </section>
        </div>
        <div className="padding60">
          <section className="container-fluid noPadding" data-aos="fade-right" data-aos-duration="400">
            <div className="row aligner">
              <div className="col-md-7 DesktopOnly">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/pos-02.jpg"
                  className="img-responsive" alt="Invoice bill generation"
                ></img>
              </div>
              <div className="col-md-4 paddingLeft">
                <h2 className="realistic-marker-highlight">Generate Bills </h2>
                <p>
                  Ease the billing process by selecting the client, reviewing
                  the items, and doing a brief analysis of the order history
                  prior to creating an invoice.
                </p>
                <a href="#" className="buttonStyle hvr-forward">
                  Try Now
                </a>
                <br></br> <br></br>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-5 mobile-only">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/pos-02.jpg"
                  className="img-responsive" alt="Invoice bill generation"
                ></img>
              </div>
            </div>
          </section>
        </div>
        <div className="padding60">
          <div className="container-fluid" data-aos="fade-left" data-aos-duration="400">
            <div className="row ContentAligner">
              <div className="col-md-2"></div>
              <div className="col-md-4">
                <h2 className="realistic-marker-highlight">View Open Orders</h2>
                <p>
                  Gives a brief overview of orders in various stages of
                  completion, such as pending manufacturer, customer follow-up &
                  outstanding payment. Enhance the retailer with a fast check
                  and take action.
                </p>
                <a href="#" className="buttonStyle hvr-forward">
                  Try Now
                </a>
                <br></br> <br></br>
              </div>
              <div className="col-md-5">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/pos-03.png"
                  className="img-responsive" alt="Customers orders showing on a laptop"
                ></img>
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>
        </div>

        <div className="padding60 blueBackground">
          <section className="container-fluid " data-aos="fade-right" data-aos-duration="400">
            <div className="row">
              <div className="col-md-12 text-center">
                <h2>
                  <span className="realistic-marker-highlight">Reports</span>
                </h2>
                <p>
                  Provides overall reports of Sales, Payments and Receivables.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="posCard text-center">
                  <h3>SALES</h3>
                  <img
                    src="https://oicweb-media.s3.ap-south-1.amazonaws.com/sales.svg"
                    width="120" alt="Sales done"
                  ></img>
                  <br></br>
                  <br></br>
                  <p>Product sold with the bill value.</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="posCard text-center">
                  <h3>PAYMENT</h3>
                  <img
                    src="https://oicweb-media.s3.ap-south-1.amazonaws.com/payment.svg"
                    width="120" alt="Payment mode"
                  ></img>
                  <br></br>
                  <br></br>
                  <p>Order info with payment mode details.</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="posCard text-center">
                  <h3>RECEIVABLES</h3>
                  <img
                    src="https://oicweb-media.s3.ap-south-1.amazonaws.com/receivable.svg"
                    width="120" alt="outstanding payment"
                  ></img>
                  <br></br>
                  <br></br>
                  <p>received and Outstanding payment.</p>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="padding60 ">
          <section className="container-fluid " data-aos="fade-left" data-aos-duration="400">
            <div className="row aligner">
              <div className="col-md-2"></div>
              <div className="col-md-4">
                <h2 className="realistic-marker-highlight">Master</h2>
                <p>
                  Updating a new vendor, make sure to fill out the vendor
                  registration form, and retailers can update the stock/new
                  arrival lenses in Lens Master to make them available to
                  customers.
                </p>
                <a href="#" className="buttonStyle hvr-forward">
                  Try Now
                </a>
                <br></br> <br></br>
                <br></br> <br></br>
              </div>
              <div className="col-md-6 noPadding">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/pos-05.jpg"
                  className="img-responsive" alt="Updating the customer data"
                ></img>
              </div>
            </div>
          </section>
        </div>
        <div className="padding60 blueBackground">
          <section className="container-fluid noPadding" data-aos="fade-right" data-aos-duration="400">
            <div className="row aligner">
              <div className="col-md-7 DesktopOnly">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/pos-04.jpg"
                  className="img-responsive" alt="Mobile showing the remainder message"
                ></img>
              </div>
              <div className="col-md-4 paddingLeft">
                <h2 className="realistic-marker-highlight">Service Reminder</h2>
                <p>
                  Delight the customer by greeting them on their special day to
                  make them think about and share a reminder for the service
                  they have opt-in.
                </p>
                <a href="#" className="buttonStyle hvr-forward">
                  Try Now
                </a>
                <br></br> <br></br>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-5 mobile-only">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/pos-04.jpg"
                  className="img-responsive" alt="Mobile showing the remainder message"
                ></img>
              </div>
            </div>
          </section>
        </div>

        <Testimonail />
      </Layout>
    </div>
  );
}
